/* Hide scrollbar for Chrome, Safari and Opera */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');
/* Add these lines to your global.css */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
/* src/styles/global.css or create a new CSS file */
.rounded-rectangular-image {
  width: 100px; /* Adjust width as needed */
  height: 400px; /* Adjust height as needed */
  object-fit: cover;
  border-radius: 75px; /* Creates a rounded rectangular shape */
  overflow: hidden;
  transition: transform 0.3s ease;
}

.rounded-rectangular-image:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
}

