.carousel-wrapper {
    overflow: hidden;
  }
  
  .carousel-item {
    position: relative;
    transform-origin: center center;
    transition: transform 0.5s ease-in-out;
    border-radius: 15px;
    overflow: hidden;
  }
  
  .slick-center .carousel-item {
    transform: scale(1.3);
    z-index: 10;
  }
  
  .slick-prev,
  .slick-next {
    width: 40px;
    height: 40px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  
  .slick-prev:hover,
  .slick-next:hover {
    background: rgba(255, 255, 255, 0.8);
  }
  